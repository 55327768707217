<template>
    <HideWhileSpinnerActive>
        <TinyNavigation :prev="{name: 'review.show', params: {id: this.id}}" class="pt-6" />

        <template v-if="!review_not_found">
            <div class="pt-10">
                <h1 class="heading-1 pb-4">{{ t('all_highlights') }}</h1>
            </div>

            <Highlight v-for="highlight in highlights" :key="highlight.id" :highlight="highlight" :video="video" />

            <div class="pb-10"></div>

        </template>
        <template v-else>
            <p class="text-white px-12 pt-10">{{ t('review_not_found') }}</p>
        </template>
    </HideWhileSpinnerActive>
</template>

<script>
import Highlight from "@/components/review/HighlightItem";
import TinyNavigation from "@/components/TinyNavigation";
import { getReview } from "@/helpers/api/ReviewHelper";
import messages from "@/helpers/storage/MessageHelper";
import HideWhileSpinnerActive from "@/wrappers/HideWhileSpinnerActive";
import { startSpinner, stopSpinner } from "@/helpers/storage/loadingSpinnerHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "ReviewShowAllHighlights",
    setup() {
        return useI18n({
            viewPrefix: "ReviewShowAllHighlights"
        });
    },
    components: { TinyNavigation, Highlight, HideWhileSpinnerActive },
    props: ['id'],
    created(){
        startSpinner()
        getReview(this.id)
            .then((review) => {
                this.highlights = review.timestamps;
                this.video = review.match.video;
                // console.log(data.timestamps);
            })
            .catch(err => {
                if (err.status === 404 || err.status === 403){
                    this.review_not_found = true;
                } else {
                    messages.error(this.t('messages.something_went_wrong', {error: err.message}));
                }
            })
            .finally(()=> stopSpinner());
    },
    data(){
        return {
            review_not_found: false,
            highlights: [],
            video: {},
        }
    },
    methods: {
        
    },
}
</script>
